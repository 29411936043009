/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import InlineCalendar from "vux/src/components/inline-calendar/index.vue";
import Group from "vux/src/components/group/index.vue";
import XSwitch from "vux/src/components/x-switch/index.vue";
import Radio from "vux/src/components/radio/index.vue";
import XButton from "vux/src/components/x-button/index.vue";
import Cell from "vux/src/components/cell/index.vue";
import Divider from "vux/src/components/divider/index.vue";
import { RxUtil } from '@/assets/util.js';
export default {
  methods: {
    onChange(val) {
      var url = _baseUrl + "/mobile/oa/getSignHistory.do";
      console.log('on-change', val);
      let formDateStr = new Date(Date.parse(val)).Format("yyyy-MM");
      //找缓存
      if (RxUtil.getSessionJSON(formDateStr) !== null) {
        let signDate = [];
        let cacheData = RxUtil.getSessionJSON(formDateStr);
        for (let i = 0; i < cacheData.length; i++) {
          if (val == cacheData[i].date.split(' ')[0]) {
            signDate.push(cacheData[i]);
          }
        }
        this.hrdutyRegister = signDate;
        return;
      }
      this.$ajax({
        url: url,
        method: 'post',
        params: {
          date: this.value
        }
      }).then(response => {
        console.log(JSON.stringify(response));
        this.hrdutyRegister = response.data.data;
      }).catch(error => {
        console.log(error);
      });
    },
    onViewChange(data, index) {
      if (index == 0) {
        return;
      }
      this.firstDate = data.firstCurrentMonthDate;
    },
    getMonthSignRecord(dateStr) {
      //dateStr:'2017-01-01'
      let formDateStr = new Date(Date.parse(dateStr)).Format("yyyy-MM");
      console.info(formDateStr);
      //有缓存
      if (RxUtil.getSessionJSON(formDateStr) !== null) {
        console.info("缓存key:" + formDateStr);
        this.thisMonthSignData = RxUtil.getSessionJSON(formDateStr);
        this.renderSignDate(this.thisMonthSignData);
        return;
      }
      var url = _baseUrl + "/mobile/oa/getUserMonthSignRecord.do";
      //根据账号请求本月考勤数据
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          date: dateStr
        }
      }).then(response => {
        if (response.data.success) {
          this.thisMonthSignData = response.data.data;
          //将当月数据缓存起来						
          RxUtil.setSessionCache(formDateStr, JSON.stringify(this.thisMonthSignData));
          this.renderSignDate(this.thisMonthSignData);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getInOrOutFlag(val) {
      if (val == "1") {
        return "签到";
      } else {
        return "签退";
      }
    },
    //1正常、2迟到、3早退
    getSignFlag(val) {
      if (val == "1") {
        return "<正常>";
      } else if (val == "2") {
        return "<迟到>";
      } else {
        return "<早退>";
      }
    },
    //签到数据与日历数据匹配
    renderSignDate(signDateArray) {
      var signDateNumArray = [];
      for (let i = 0; i < signDateArray.length; i++) {
        var signDate = signDateArray[i].date.split(' ')[0];
        console.info("后台的数据:" + signDate);
        signDateNumArray.push(signDate);
      }
      console.info("签到的日期:" + signDateNumArray);
      this.buildSlotFn = (line, index, data) => {
        //使用日期进行匹配
        return signDateNumArray.indexOf(data.formatedDate) != -1 ? '<div style="font-size:12px;text-align:center;"><span style="display:inline-block;width:5px;height:5px;background-color:red;border-radius:50%;"></span></div>' : '<div style="height:19px;"></div>';
      };
    }
  },
  data() {
    return {
      show: true,
      value: '',
      listValue: '',
      range: false,
      highlightWeekend: false,
      return6Rows: true,
      hideHeader: false,
      hideWeekList: false,
      replaceTextList: {},
      replace: false,
      changeWeeksList: false,
      weeksList: ['日', '一', '二', '三', '四', '五', '六 '],
      useCustomFn: false,
      buildSlotFn: () => '',
      disablePast: false,
      disableFuture: true,
      hrdutyRegister: [],
      showLastMonth: true,
      showNextMonth: true,
      thisMonthSignData: [],
      firstDate: "",
      renderOnChange: true
    };
  },
  watch: {
    replace(val) {
      this.replaceTextList = val ? {
        'TODAY': '今'
      } : {};
    },
    useCustomFn(val) {
      this.buildSlotFn = val ? (line, index, data) => {
        //console.info("这里的值是:" + line + "," +index+ ","  + JSON.stringify(data));
        return /9/.test(data.date) ? '<div style="font-size:12px;text-align:center;"><span style="display:inline-block;width:5px;height:5px;background-color:red;border-radius:50%;"></span></div>' : '<div style="height:19px;"></div>';
      } : () => '';
    },
    changeWeeksList(val) {
      this.weeksList = val ? ['日', '一', '二', '三', '四', '五', '六 '] : ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    },
    firstDate(newVal, oldVal) {
      //console.info("新值:" + JSON.stringify(newVal));
      this.getMonthSignRecord(newVal);
    }
  },
  components: {
    InlineCalendar,
    Group,
    XSwitch,
    Radio,
    XButton,
    Cell,
    Divider
  },
  created() {
    let nowDate = new Date();
    this.firstDate = nowDate.getFullYear() + "-" + (nowDate.getMonth() + 1) + "-" + nowDate.getDay();
    //this.getMonthSignRecord(this.firstDate);
  }
};